import { createGlobalStyle, CSSObject } from "styled-components"
import tw, { globalStyles, theme } from "twin.macro"

const globals = {
  // Hide the cookiebot logo
  "#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo": {
    display: "none",
  },
  ".blue": {
    ...tw`text-blue`,
  },
  ".bold": {
    ...tw`font-medium`,
  },
  ".flickity-button": {
    ...tw`focus:(ring-blue ring-4)`,
  },
  ".flickity-page-dots .dot": {
    ...tw`w-2 h-2 mx-1 my-0`,
  },
  ".flickity-page-dots .is-selected": {
    ...tw`bg-blue`,
  },
  ".gold": {
    ...tw`text-yellow-dark`,
  },
  ".intercom-launcher-badge-frame": {
    ...tw`z-30!`,
  },
  ".intercom-launcher-frame": {
    ...tw`z-30!`,
  },
  ".intercom-lightweight-app": {
    ...tw`z-30!`,
  },
  ".intercom-messenger-frame": {
    ...tw`z-30!`,
  },
  ".orange": {
    ...tw`text-orange`,
  },
  ".orange_italic": {
    ...tw`italic text-orange`,
  },
  ".subtitle": {
    ...tw`mt-5 mb-4 font-light font-heading text-21 tablet:text-22 laptop:text-26`,
  },
  ".tag": {
    ...tw`p-1.5 px-3 font-medium text-14 text-white drop-shadow-md rounded-md bg-[#0E4D5E]`,
  },
  /* width */
"::-webkit-scrollbar": {
    ...tw`w-3.5`,
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    ...tw`bg-gray-4 hover:bg-gray-3 active:bg-gray-2`,
  },
  /* Track */
  "::-webkit-scrollbar-track": {
    ...tw`bg-gray-6`,
  },

  "::selection": {
    ...tw`bg-blue-baby`,
  },

  "@media(width < 768px)": {
    "[data-screen='laptopabove']": {
      ...tw`hidden`,
    },
  },
  "[data-screen='laptopbelow']": {
    ...tw`laptop:hidden`,
  },

  a: {
    ...tw`focus:outline-none focus-visible:outline-black`,
  },
  // Hide the cookiebot logo
  "a#CybotCookiebotDialogPoweredbyCybot, div#CybotCookiebotDialogPoweredByText": {
    display: "none",
  },
  body: {
    ...tw`m-0 antialiased text-body`,
    WebkitTapHighlightColor: theme`colors.blue.light`,
  },
  button: {
    ...tw`focus:outline-none focus-visible:outline-black! [font-weight:inherit]`,
  },
  "footer a": {
    ...tw`focus-visible:outline-white`,
  },
  "footer button": {
    ...tw`focus-visible:outline-white`,
  },
  input: {
    ...tw`focus:(outline-none border-blue) `,
  },
  "input:-webkit-autofill": {
    ...tw`delay-[9999s]`,
  },
  "input:-webkit-autofill:focus": {
    transition: "background-color 600000s 0s, color 600000s 0s;",
  },
  select: {
    ...tw`focus:(outline-none border-blue) `,
  },
  "select:-webkit-autofill": {
    ...tw`delay-[9999s]`,
  },
}

const GlobalStyles = createGlobalStyle(globalStyles as CSSObject, globals)

export default GlobalStyles
