exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-storyblok-documentation-tsx": () => import("./../../../src/pages/storyblok-documentation.tsx" /* webpackChunkName: "component---src-pages-storyblok-documentation-tsx" */),
  "component---src-templates-academy-chapter-template-tsx": () => import("./../../../src/templates/AcademyChapterTemplate.tsx" /* webpackChunkName: "component---src-templates-academy-chapter-template-tsx" */),
  "component---src-templates-academy-course-template-tsx": () => import("./../../../src/templates/AcademyCourseTemplate.tsx" /* webpackChunkName: "component---src-templates-academy-course-template-tsx" */),
  "component---src-templates-app-info-template-tsx": () => import("./../../../src/templates/AppInfoTemplate.tsx" /* webpackChunkName: "component---src-templates-app-info-template-tsx" */),
  "component---src-templates-background-template-tsx": () => import("./../../../src/templates/BackgroundTemplate.tsx" /* webpackChunkName: "component---src-templates-background-template-tsx" */),
  "component---src-templates-blog-index-template-tsx": () => import("./../../../src/templates/BlogIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-index-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-customer-success-story-template-tsx": () => import("./../../../src/templates/CustomerSuccessStoryTemplate.tsx" /* webpackChunkName: "component---src-templates-customer-success-story-template-tsx" */),
  "component---src-templates-help-video-template-tsx": () => import("./../../../src/templates/HelpVideoTemplate.tsx" /* webpackChunkName: "component---src-templates-help-video-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-pricing-page-template-tsx": () => import("./../../../src/templates/PricingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-pricing-page-template-tsx" */),
  "component---src-templates-register-template-tsx": () => import("./../../../src/templates/RegisterTemplate.tsx" /* webpackChunkName: "component---src-templates-register-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-usp-template-tsx": () => import("./../../../src/templates/USPTemplate.tsx" /* webpackChunkName: "component---src-templates-usp-template-tsx" */),
  "component---src-templates-virtual-background-platform-template-tsx": () => import("./../../../src/templates/VirtualBackgroundPlatformTemplate.tsx" /* webpackChunkName: "component---src-templates-virtual-background-platform-template-tsx" */),
  "component---src-templates-virtual-background-tag-template-tsx": () => import("./../../../src/templates/VirtualBackgroundTagTemplate.tsx" /* webpackChunkName: "component---src-templates-virtual-background-tag-template-tsx" */),
  "component---src-templates-virtual-backgrounds-index-template-tsx": () => import("./../../../src/templates/VirtualBackgroundsIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-virtual-backgrounds-index-template-tsx" */)
}

